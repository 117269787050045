<template>
  <div class="changePswd">
    <div class="backBox" @click="toPage">
      {{i18nData.to}} <i class="el-icon-right"></i>
    </div>
    <div class="interest">
      <div> {{i18nData.name}}</div>
      <div></div>
    </div>
    <div class="changeBox">
      <el-form
        class="formBox"
        :model="formData"
        :rules="rules"
        ref="formData"
        label-width="100px"
        :label-position="labelPosition"
      >
        <el-form-item class="formItem" prop="name" :label="i18nData.username">
          <el-input
            class="input"
            v-model="formData.name"
            :placeholder="i18nData.usernametip"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item class="formItem" prop="phone" :label="i18nData.telephone">
          <el-input
            class="input"
            v-model="formData.phone"
            :placeholder="i18nData.telephonetip"
            ><template slot="prefix">+86</template></el-input
          >
        </el-form-item>
        <el-form-item class="formItem" prop="email" :label="i18nData.email">
          <el-input
            class="input"
            v-model="formData.email"
            :placeholder="i18nData.emailtip"
          ></el-input>
        </el-form-item>
        <el-form-item class="formItem" prop="need" :label="i18nData.need">
          <el-input
            class="input textarea"
            type="textarea"
            v-model="formData.need"
            :placeholder="i18nData.needtip"
            :autosize="{ minRows: 6, maxRows: 12 }"
          ></el-input>
        </el-form-item>
        <el-form-item class="formItem">
          <el-button
            class="submitBtn"
            type="primary"
            @click="submitForm('formData')"
            >{{i18nData.button}}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { deb_, thr_ } from "./../common/util.js";

export default {
  name: "mySubmit",
  components: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入旧密码"));
      } else {
        // if (this.formData.oldPaswd !== "") {
        //   this.$refs.formData.validateField("oldPaswd");
        // }
        callback();
      }
    };
    var validateNewPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        // if (this.formData.newPaswd !== "") {
        //   this.$refs.formData.validateField("newPaswd");
        // }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.formData.newPaswd) {
        callback(new Error("两次输入新密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      labelPosition: "right",
      formData: {
        diyname: "message",
        name: "",
        phone: "",
        email: "",
        need: ""
      },
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur"
          }
        ],
        need: [{ required: true, message: "请输入您的需求", trigger: "blur" }]
      }
    };
  },
  created() {
    this.$store.state.myActiveIndex = this.$route.name;
  },
  methods: {
    toPage() {
      this.$router.replace({
        path: "/my/mySublist"
      });
    },
    submitForm: thr_(function(formName) {
      console.log(this.$refs[formName]);
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let params = {
            diyname: this.formData.diyname,
            name: this.formData.name,
            telephone: this.formData.phone,
            email: this.formData.email,
            content: this.formData.need
          };
          let res = await this.$api.https.postForm(params);
          this.$message({
            message: res.msg,
            duration: 2000
          });
          if (res.code) {
            setTimeout(() => {
              this.$router.replace({
                path: "/my/mySublist",
                query: { diyname: this.formData.diyname }
              });
            }, 1500);
          }
        } else {
          // console.log("error submit!!");
          this.$message({
            message: "error submit!!",
            type: "warning"
          });
          return false;
        }
      });
    }, 2000)
  }
};
</script>
<style lang="scss" scoped>
$vue_color: #9b0000;
@mixin interest($width) {
  .interest {
    text-align: center;
    height: 40px;
    width: $width;
    font-size: 32px;

    div:nth-child(2) {
      background: #cbe8ff;
      width: $width;
      height: 11px;
      border-radius: 5px;
      margin: -5px 0 0 0;
    }
  }
}
.changePswd {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  text-align: left;
  padding: 20px 0 60px 40px;
  .backBox {
    align-self: flex-end;
    font-size: 14px;
    color: $vue_color;
    display: flex;
    align-items: center;
    i {
      margin-left: 10px;
      font-size: 20px;
    }
  }
  .interest {
    margin: 20px 0 30px;
  }
  @include interest(350px);
  .changeBox {
    padding: 40px 0;
    width: 500px;
    // height: 500px;
    border: 1px solid #dcdcdc99;
    border-radius: 5px;
    .formBox {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .typeTabs {
        margin-bottom: 10px;
      }
      ::v-deep.el-tabs__nav-wrap::after {
        background-color: transparent;
      }
      .formItem {
        // margin-bottom: 5px;
        .input {
          width: 280px;
        }
        .input.textarea {
        }
        ::v-deep.el-input__inner {
          border-left: none;
          border-right: none;
          border-top: none;
          border-radius: 0;
        }
      }
      .submitBtn {
        margin-top: 30px;
        background-color: $vue_color;
        border-color: $vue_color;
        width: 120px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        border-radius: 5px;
      }
    }
  }
}
</style>
